import React from 'react'
import SbEditable from 'storyblok-react'
import { Box } from 'reflexbox'

import Highlight from 'src/components/Highlight/Highlight'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './ArticlesList.module.scss'
import { Props } from './types'

const ArticlesList = ({ articles, blok }: Props) => {
  const { title } = blok
  return (
    <SbEditable content={blok}>
      {title && (
        <Container
          paddingTop={[20, null, null, 23]}
          paddingBottom={[10, null, null, 15]}
        >
          <Row alignItems="center" flexDirection="column">
            <Column width={[1, null, null, 10 / 12]}>
              <Box as="h2" className={styles.title}>
                {title}
              </Box>
            </Column>
          </Row>
        </Container>
      )}
      {articles?.length > 0 &&
        articles.map((item: any, index: number) => {
          const second = (index + 1) % 2 === 0
          const third = (index + 1) % 3 === 0

          return (
            <Highlight
              item={item}
              key={item.id}
              imageLeft={index % 2 !== 0}
              theme={third ? 'gold' : second ? 'blue' : ''}
              useSummary
            />
          )
        })}
    </SbEditable>
  )
}

export default ArticlesList
