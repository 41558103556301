import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { getLinkAttributes } from 'src/helpers/link'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import BigCtaButton from 'src/components/UI/Buttons/BigCtaButton/BigCtaButton'
import Checkbox from 'src/components/UI/Checkbox/Checkbox'
import PolarCircle from 'src/components/UI/Elements/PolarCircle/PolarCircle'
import Column from 'src/components/UI/Grid/Column'
import Row from 'src/components/UI/Grid/Row'
import Container from 'src/components/UI/Grid/Container'
import Input from 'src/components/UI/Input/Input'

import * as styles from './NominationForm.module.scss'
// import { Props } from './types'

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Nominate = ({ blok }: any) => {
  const [form, setForm] = useState({
    nomination: '',
    motivation: '',
    concent: false,
  })
  const [success, setSuccess] = useState(false)

  const { title, description, link, about_title, about_image, about_text } =
    blok

  const { title: linkTitle, url: linkUrl }: { title: string; url: string } =
    link?.length > 0 ? getLinkAttributes(link[0]) : { title: '', url: '' }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'nomination', ...form }),
    })
      .then(() => {
        setSuccess(true)
        console.log('Success!')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChange = (e: any) =>
    setForm({ ...form, [e.target.name]: e.target.value })

  return (
    <SbEditable content={blok}>
      {(about_title || about_image?.filename || about_text) && (
        <Flex
          className={styles.nominateHeader}
          flexDirection={['column', null, null, 'row']}
          width={1}
        >
          <Container>
            <Row alignItems="space-between">
              <Column
                className={styles.nominateImage}
                width={[1, null, null, 6 / 12]}
              >
                {about_image?.filename && (
                  <Image
                    src={about_image.filename}
                    alt={about_title}
                    sizes={'50vw'}
                  />
                )}
              </Column>
              <Column
                paddingY={[8, null, null, 25]}
                width={[1, null, null, 6 / 12]}
              >
                <Row justifyContent="center" alignItems="center" height="100%">
                  <Column width={[1, null, null, 4 / 6]}>
                    {about_title && (
                      <Box mb={8} as="h3">
                        {about_title}
                      </Box>
                    )}
                    {about_text && (
                      <Markdown themeLight={true} source={about_text} />
                    )}
                  </Column>
                </Row>
              </Column>
            </Row>
          </Container>
        </Flex>
      )}
    </SbEditable>
  )
}

export default Nominate
