import { useObserver } from 'mobx-react'
import React from 'react'
import { Box, Flex } from 'reflexbox'

import useStore from 'src/stores/useStore'

import Markdown from '../Markdown/Markdown'
import { CloseIcon } from '../UI/Elements/Icon'
import Image from '../UI/Image/Image'
import * as styles from './MemberOverlay.module.scss'
import { Props } from './types'

const MemberOverlay = ({ member }: Props) => {
  const { ui } = useStore()

  const onClose = () => {
    ui.setMemberActive(false)
  }

  const { image, name, title_and_contact_info, story }: any = member
    ? member
    : {}
  return useObserver(() =>
    ui.memberActive && member ? (
      <Flex alignItems="center" className={styles.wrapper}>
        <div onClick={onClose} className={styles.backdrop} />
        <Flex
          className={styles.content}
          flexDirection={['column', null, null, 'row']}
          maxHeight={['100vh', null, null, '90vh']}
          // height={['100%', null, null, 1]}
          paddingX={[6, null, null, 0]}
          paddingY={[6, null, null, 0]}
          width={[1, null, null, 10 / 12]}
          margin="auto"
        >
          <Flex
            width={[1, null, null, 4 / 10]}
            flexDirection={['row', null, null, 'column']}
          >
            <Box className={styles.imageWrapper} width={[2 / 5, null, null, 1]}>
              {image && image.filename && (
                <Image src={image.filename} alt={name} sizes={'40vw'} />
              )}
            </Box>
            <Flex
              width={[3 / 5, null, null, 1]}
              paddingY={[4, null, null, 18]}
              paddingX={[6, null, null, 13]}
              className={styles.titleSection}
              alignItems="center"
            >
              <Markdown source={title_and_contact_info} />
            </Flex>
          </Flex>
          <Flex
            justifyContent="center"
            paddingY={[6, null, null, 16]}
            width={[1, null, null, 6 / 10]}
            className={styles.story}
          >
            <Flex
              flexDirection="column"
              width={[1, null, null, 4 / 6]}
              paddingX={[6, null, null, 0]}
            >
              <Box as="h4" paddingRight={[10, null, null, 0]}>
                {name}
              </Box>
              <Markdown source={story} />
              <Box paddingBottom={[6, null, null, 16]} />
            </Flex>
            <Flex
              onClick={onClose}
              padding={4}
              className={styles.closeButton}
              as="button"
            >
              <CloseIcon />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    ) : null
  )
}

export default MemberOverlay
