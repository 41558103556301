import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import scrollIntoView from 'scroll-into-view'
import isNode from 'is-node'

import { getSeo } from 'src/helpers/getSeo'
import ShareSection from 'src/components/ShareSection/ShareSection'
import BigCtaButton from 'src/components/UI/Buttons/BigCtaButton/BigCtaButton'
import LaureateHero from 'src/components/Laureate/LaureateHero/LaureateHero'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Markdown from 'src/components/Markdown/Markdown'
import Components from 'src/storyblok/Components'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { stringToSlug } from 'src/helpers/stringToSlug'
import LaureatesList from 'src/components/LaureatesList/LaureatesList'
import { ChevronDown, TuneIcon } from 'src/components/UI/Elements/Icon'
import SEO from 'src/components/SEO/SEO'
import PolarCircle from 'src/components/UI/Elements/PolarCircle/PolarCircle'

import * as styles from './PageLaureate.module.scss'
import { Props } from './types'

const PageLaureate = ({ blok }: Props) => {
  const [activeSection, setSection] = useState(-1)
  const {
    year,
    name,
    hero_image,
    hero_image_mobile,
    hero_overlay,
    announcement_youtube_id,
    description,
    playlist,
    chapters,
    related_artists,
    laureates,
    seo_data,
  } = blok
  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    description: description ? description : '',
    title: name ? name : '',
    image: hero_image?.filename ? hero_image.filename : '',
  })

  let absoluteUrl = ''

  if (!isNode && window && window.location) {
    absoluteUrl = window.location.href
  }

  const related =
    related_artists &&
    related_artists
      .map((artist: any) => {
        const found = laureates
          ? laureates.find((item: any) => item.id === artist)
          : null
        return found
      })
      .filter((artist: any) => artist)

  const scrollToChapter = (e: any, id: string) => {
    e.preventDefault()
    const el = document.getElementById(id)
    if (el) {
      scrollIntoView(el, {
        time: 2000,
        align: {
          top: 0,
          left: 0,
        },
      })
    }
  }

  const percentageSeen = (section: any, index: number) => {
    // Get the relevant measurements and positions
    const viewportHeight = window.innerHeight
    const scrollTop = window.scrollY
    const elementOffsetTop = section.el.offsetTop
    const elementHeight = section.el.offsetHeight

    // Calculate percentage of the element that's been seen
    const distance = scrollTop + viewportHeight - elementOffsetTop
    const percentage = Math.round(
      distance / ((viewportHeight + elementHeight) / 100)
    )

    // Restrict the range to between 0 and 100
    return {
      ...section,
      index,
      visible: Math.min(100, Math.max(0, percentage)),
    }
  }

  const onScroll = (array: any) => {
    const visibleArray = array.map((section: any, index: number) => {
      return percentageSeen(section, index - 1)
    })
    let el = {
      visible: 0,
      index: -1,
    }
    let prevFull = false
    visibleArray.forEach((section: any, index: number) => {
      if (section.index === -1 && section.visible > 0) {
        el = section
      }
      if (el.index === -1 && el.visible < 95) {
      } else {
        const treshold = 20
        if (section.visible > el.visible) {
          el = section
        }
        if (prevFull && section.visible > treshold) {
          el = section
        }
        if (section.visible > 80) {
          prevFull = true
        } else {
          prevFull = false
        }
      }
    })
    const wrapper = document.getElementById('chapter-wrapper')
    if (el && el.visible && el.index > -1) {
      wrapper?.classList.add('active')
      if (activeSection !== el.index) {
        setSection(el.index)
        const roller = document.getElementById('roller')
        if (roller) {
          roller.style.transform = `translateY(-${el.index * 70}px)`
        }
      }
    } else {
      wrapper?.classList.remove('active')
    }
  }

  useEffect(() => {
    if (document) {
      const sections = document.getElementsByTagName('section')
      if (sections) {
        const array: any[] = []
        for (const item of sections) {
          array.push({
            el: item,
          })
        }
        onScroll(array)
        window.addEventListener('scroll', () => onScroll(array), {
          passive: true,
        })
      }
    }
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const halfChapterLength = chapters ? Math.ceil(chapters.length / 2) : 0

  return (
    <SbEditable content={blok}>
      <SEO
        title={seo_title}
        metaDescription={seo_description}
        image={seo_image}
      />
      <Box
        id="chapter-wrapper"
        className={styles.chapterNav}
        width={1}
        height="70px"
      >
        <Flex>
          <Flex className={styles.yellow} width={4 / 12}></Flex>
          <Flex
            height="70px"
            paddingX={['24px', null, null, 0]}
            className={styles.blue}
            width={[1, null, null, 8 / 12]}
            flexDirection="row"
          >
            <Flex
              marginLeft={[0, null, null, `${(1 / 8) * 100}vw`]}
              width={2 / 8}
              alignItems="center"
              height="70px"
            >
              {'Chapter'}
            </Flex>
            <Flex width={1 / 8}>
              <Flex
                marginRight={2}
                alignItems="center"
                className={styles.chevUp}
                as="button"
                onClick={(e: any) => {
                  scrollToChapter(
                    e,
                    stringToSlug(chapters[activeSection - 1].title)
                  )
                }}
              >
                <ChevronDown width={18} height={18} />
              </Flex>
              <Flex
                onClick={(e: any) => {
                  scrollToChapter(
                    e,
                    stringToSlug(chapters[activeSection + 1].title)
                  )
                }}
                alignItems="center"
                className={styles.chevDown}
                as="button"
              >
                <ChevronDown width={18} height={18} />
              </Flex>
            </Flex>
            <Flex
              width={[6 / 8, null, null, 4 / 8]}
              className={styles.chapterRoller}
              id="roller"
              flexDirection="column"
            >
              {chapters &&
                chapters.length > 0 &&
                chapters.map((chapter: any, index: number) => (
                  <Flex
                    alignItems="center"
                    height="70px"
                    flexShrink={0}
                    key={index}
                    width={1}
                  >
                    {`${index + 1}. ${chapter.title}`}
                  </Flex>
                ))}
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Flex as="section" id="top-section" flexDirection="column" width={1}>
        <LaureateHero
          year={year}
          title={name}
          image={hero_image}
          imageMobile={hero_image_mobile}
          youtube_id={announcement_youtube_id}
          hero_overlay={hero_overlay}
        />
        <Container
          style={{ position: 'relative', overflow: 'hidden' }}
          paddingTop={[12, null, null, 22]}
          paddingBottom={[12, null, null, 40]}
        >
          <PolarCircle className={styles.circle} />
          <Row width={'auto'}>
            <Column
              width={[1, null, null, 7 / 12]}
              marginLeft={[0, null, null, `${(4 / 12) * 100}vw`]}
              className={styles.information}
            >
              <Markdown source={description} />
            </Column>
          </Row>
        </Container>
        <Flex
          flexDirection={['column', null, null, 'row']}
          className={styles.chapterHeader}
        >
          <Flex
            width={[1, null, null, 4 / 12]}
            flexDirection="column"
            justifyContent="space-between"
            className={styles.left}
          >
            {playlist && (
              <BigCtaButton
                className={styles.button}
                to={playlist}
                customIcon={<TuneIcon />}
              >
                Listen to the official Playlist
              </BigCtaButton>
            )}
          </Flex>
          <Flex
            paddingTop={12}
            paddingBottom={[24]}
            justifyContent="center"
            alignItems="flex-start"
            width={[1, null, null, 8 / 12]}
            className={styles.right}
          >
            <Flex
              alignItems={['flex-end']}
              paddingX={[9, null, null, 0]}
              width={[1, null, null, 6 / 8]}
              flexDirection="column"
            >
              <Box marginBottom={6} width={[5 / 6, null, null, 1]} as="h2">
                Chapters
              </Box>
              <Flex
                width={[5 / 6, null, null, 1]}
                flexDirection={['column', null, null, 'row']}
              >
                <Flex width={[1, null, null, 1 / 2]} flexDirection="column">
                  {chapters &&
                    chapters.length > 0 &&
                    chapters.map((chapter: any, index: number) =>
                      index < halfChapterLength ? (
                        <Box marginBottom={6} key={index} width={1}>
                          <LinkComponent
                            onClick={(e: any) =>
                              scrollToChapter(e, stringToSlug(chapter.title))
                            }
                            to={`#${stringToSlug(chapter.title)}`}
                          >
                            {`${index + 1}. ${chapter.title}`}
                          </LinkComponent>
                        </Box>
                      ) : null
                    )}
                </Flex>
                <Flex width={[1, null, null, 1 / 2]} flexDirection="column">
                  {chapters &&
                    chapters.length > 0 &&
                    chapters.map((chapter: any, index: number) =>
                      index > halfChapterLength - 1 ? (
                        <Box marginBottom={6} key={index} width={1}>
                          <LinkComponent
                            onClick={(e: any) =>
                              scrollToChapter(e, stringToSlug(chapter.title))
                            }
                            to={`#${stringToSlug(chapter.title)}`}
                          >
                            {`${index + 1}. ${chapter.title}`}
                          </LinkComponent>
                        </Box>
                      ) : null
                    )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection="column" width={1}>
        <Box width={1}>
          {chapters &&
            chapters.map((blok: any, index: number) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                index,
              })
            )}
        </Box>
        <Flex flexDirection="column">
          {related && related.length > 0 && (
            <React.Fragment>
              <Flex
                width={1}
                justifyContent="center"
                paddingTop={[14]}
                paddingBottom={[8]}
              >
                <Container
                  justifyContent={['flex-start', null, null, 'center']}
                >
                  <Row width={[1, null, null, 10 / 12]}>
                    <Column width={1}>
                      <Box className={styles.discoverTitle} as="h3">
                        {'Discover similar artists'}
                      </Box>
                    </Column>
                  </Row>
                </Container>
              </Flex>
              <LaureatesList laureates={related} gridType="default" />
            </React.Fragment>
          )}
        </Flex>
        <ShareSection
          extraLink={{ url: '/laureates', text: 'Show all Laureates' }}
        />
      </Flex>
    </SbEditable>
  )
}

export default PageLaureate
