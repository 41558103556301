import React from 'react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'

import { getTitleParts } from 'src/helpers/getTitleParts'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Image from 'src/components/UI/Image/Image'

import * as styles from './LaureateCard.module.scss'
import { Props } from './types'

const LaureateCard = ({ item, type }: Props) => {
  const { name, image, slug, year } = item

  const nameParts = name?.split(' ')?.length > 0 ? name.split(' ') : []
  const namePartGroups = getTitleParts(name)
  let wrapperStyle = styles.standard
  if (type === 'small') {
    wrapperStyle = styles.small
  }

  return (
    <LinkComponent
      className={[styles.artist, wrapperStyle].join(' ')}
      to={`/${slug}`}
      key={slug}
    >
      <Box className={styles.listImage} width={[1 / 2]}>
        {image?.filename && (
          <Image src={image.filename} alt={name} sizes={'50vw'} />
        )}
      </Box>
      <Flex
        className={styles.artistInfo}
        paddingY={type === 'small' ? [5, null, null, 6] : [5, null, null, 10]}
        paddingX={type === 'small' ? [4, null, null, 5] : [4, null, null, 6]}
        flexDirection={'column'}
        width={1 / 2}
        height="100%"
        justifyContent="center"
      >
        <Box className={styles.artistYear} as="span">
          {year}
        </Box>
        <Flex
          as="h2"
          flexDirection="column"
          className={classnames(
            styles.artistName,
            nameParts?.length > 2 ? styles.multiLine : ''
          )}
          width={1}
          // width={type === 'small' ? 1 : [1, null, null, 2 / 3]}
        >
          {namePartGroups?.length > 0 &&
            namePartGroups.map((part, index) => {
              return part && Array.isArray(part) && part?.length > 0 ? (
                <Box
                  className={styles.artistNameText}
                  as="span"
                  width={1}
                  key={`${slug}-part-${index}`}
                >
                  {part.map(
                    (groupPart, index) =>
                      `${groupPart}${index < part.length - 1 ? ` ` : ``}`
                  )}
                </Box>
              ) : (
                <Box
                  className={styles.artistNameText}
                  as="span"
                  width={1}
                  key={`${slug}-part-${index}-${part}`}
                >
                  {part}
                </Box>
              )
            })}
        </Flex>
      </Flex>
    </LinkComponent>
  )
}

export default LaureateCard
