// extracted by mini-css-extract-plugin
export var backButton = "PageLaureate-module--backButton--c8W18";
export var blue = "PageLaureate-module--blue--oT0Qc";
export var button = "PageLaureate-module--button--7T3x1";
export var chapterHeader = "PageLaureate-module--chapterHeader--tCTy0";
export var chapterNav = "PageLaureate-module--chapterNav--UfHaR";
export var chapterRoller = "PageLaureate-module--chapterRoller--lydIj";
export var chevDown = "PageLaureate-module--chevDown--bMf5C";
export var chevUp = "PageLaureate-module--chevUp--oVwxb";
export var circle = "PageLaureate-module--circle--dZcps";
export var discoverTitle = "PageLaureate-module--discoverTitle--SaGPW";
export var information = "PageLaureate-module--information--L+fo0";
export var left = "PageLaureate-module--left--acoaD";
export var right = "PageLaureate-module--right--9GMfM";
export var yellow = "PageLaureate-module--yellow--U30be";