// extracted by mini-css-extract-plugin
export var asset = "PagePress-module--asset--ltrFm";
export var circle = "PagePress-module--circle--SB1dH";
export var contact = "PagePress-module--contact--poIgp";
export var contactImage = "PagePress-module--contactImage--RdXPS";
export var contactLabel = "PagePress-module--contactLabel--oCE9V";
export var contactName = "PagePress-module--contactName--8QmhN";
export var contactSection = "PagePress-module--contactSection--uU0GN";
export var explanation = "PagePress-module--explanation--cAU05";
export var hero = "PagePress-module--hero--Z0d69";
export var image = "PagePress-module--image--Fpvbc";
export var item = "PagePress-module--item--AXJMY";
export var laureateTitle = "PagePress-module--laureateTitle--dmIWm";
export var logoSection = "PagePress-module--logoSection--f6PhM";
export var socialLink = "PagePress-module--socialLink--BL4kK";
export var text = "PagePress-module--text--ZGgH1";
export var title = "PagePress-module--title--b8hXl";