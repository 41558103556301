import * as React from 'react'
import classnames from 'classnames'
import ReactMarkdown from 'react-markdown'
// import remarkBreaks from 'remark-breaks'

import * as styles from './Markdown.module.scss'

// import { getIntlUrl } from 'src/utils/intl'

type Props = {
  className?: any
  source: any
  themeLight?: boolean
}

const Markdown = ({
  className = '',
  source,
  themeLight = false,
}: Props): JSX.Element => {
  const linkTarget = (url = '') => {
    let returnTarget
    const localUrl = url?.split('?')?.length > 0 ? url.split('?')[0] : ''

    const isNew = localUrl.substring(0, 4) === 'http'
    const isSpotifyUri = localUrl.substring(0, 7) === 'spotify'
    const isDownload = localUrl.substring(0, 9) === 'data:text'
    const isMail = localUrl.startsWith('mailto')
    const isPhone = localUrl.startsWith('tel')
    const isPdf = localUrl.substring(-4, 4) === '.pdf'

    const isExternal =
      isNew || isMail || isPhone || isDownload || isSpotifyUri || isPdf

    if (isExternal) {
      returnTarget = '_blank'
    }

    return returnTarget
  }

  return (
    <div
      className={classnames(
        styles.markdown,
        themeLight ? styles.themeLight : ''
      )}
    >
      <ReactMarkdown
        components={{
          a(props) {
            const { href, children } = props
            const target = linkTarget(href)
            return (
              <a
                href={href}
                target={target}
                rel={target === '_blank' ? 'noopener noreferrer' : ''}
              >
                {children}
              </a>
            )
          },
        }}
      >
        {source}
      </ReactMarkdown>
    </div>
  )
}

export default Markdown
