import React from 'react'
import { Flex, Box } from 'reflexbox'

import Video from 'src/storyblok/Modules/Video/Video'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import BigCtaButton from 'src/components/UI/Buttons/BigCtaButton/BigCtaButton'

import * as styles from './VideoList.module.scss'
import { Props } from './types'

const VideoList = ({ videos, title, ctaArea = true }: Props) => {
  return (
    <React.Fragment>
      {title && (
        <Container
          paddingTop={[20, null, null, 23]}
          paddingBottom={[10, null, null, 15]}
        >
          <Row alignItems="center" flexDirection="column">
            <Column width={[1, null, null, 10 / 12]}>
              <Box as="h2" className={styles.title}>
                {title}
              </Box>
            </Column>
          </Row>
        </Container>
      )}
      {videos?.length > 0 && (
        <Container>
          <Row>
            {videos.map((blok: any) => {
              return (
                <Column
                  width={[1, null, null, 4 / 12]}
                  marginBottom={[4, null, null, 9]}
                  key={blok.id}
                >
                  <Video className={styles.video} blok={blok} />
                </Column>
              )
            })}
          </Row>
        </Container>
      )}
      {ctaArea && (
        <Flex className={styles.ctaArea} justifyContent="flex-end">
          <BigCtaButton to={'/polar-talks/videos'}>Show all Talks</BigCtaButton>
        </Flex>
      )}
    </React.Fragment>
  )
}

export default VideoList
