import React, { useState, createRef, useEffect } from 'react'
// import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
// import classnames from 'classnames'

// import MusicButton from 'src/components/MusicButton/MusicButton'

import * as styles from './SpotifyPlayer.module.scss'
// import { Props } from './types'

const SpotifyPlayer = ({ blok }) => {
  // const container: React.RefObject<HTMLDivElement> = createRef()
  // const [showPlayer, setShowPlayer] = useState(false)
  const { spotify_uri } = blok

  let id = null
  // Split uri into parts
  const uri_parts =
    spotify_uri?.split(':').length > 0 ? spotify_uri?.split(':') : []

  if (uri_parts.length > 0) {
    // Remove first part

    const id_parts = uri_parts.slice(Math.max(uri_parts.length - 2, 0))

    // Make string
    id = id_parts.join('/')
  }

  // const playerObserver = new IntersectionObserver(
  //   (entries) => {
  //     if (!entries || entries.length <= 0) {
  //       return
  //     }

  //     if (entries[0].isIntersecting) {
  //       setShowPlayer(true)
  //       playerObserver.disconnect()
  //     }
  //   },
  //   {
  //     rootMargin: '100px 0px',
  //     threshold: 0.25,
  //   }
  // )

  // useEffect(() => {
  //   if (window && 'IntersectionObserver' in window) {
  //     if (container && container.current) {
  //       playerObserver.observe(container.current)
  //     }
  //   } else {
  //     setShowPlayer(true)
  //   }
  // }, [container])

  const srcEmbed = `https://open.spotify.com/embed/${id}`
  const srcLink = `https://open.spotify.com/${id}`

  return id ? (
    <SbEditable content={blok}>
      <div className={styles.wrapper}>
        <iframe
          src={srcEmbed}
          width="300"
          height="80"
          frameBorder="0"
          allowTransparency={true}
          allow="encrypted-media"
        />
        {/* <MusicButton slug={srcLink} /> */}
        {/* {showPlayer ? (
          <iframe
            src="https://open.spotify.com/embed/album/1DFixLWuPkv3KT3TnV35m3"
            width="300"
            height="80"
            frameBorder="0"
            allowTransparency="true"
            allow="encrypted-media"
           />
        ) : null} */}
      </div>
    </SbEditable>
  ) : null
}

export default SpotifyPlayer
