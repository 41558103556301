// import { useObserver } from 'mobx-react'
import React from 'react'
import { Box, Flex } from 'reflexbox'
import isNode from 'is-node'
import Lottie from 'react-lottie'
import { reaction } from 'mobx'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import MobileNav from 'src/components/MobileNav/MobileNav'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import stores from 'src/stores/decorator'
import Logotype from 'src/components/UI/Logotype/Logotype'

import * as animationData from './hamburger.json'
import { Props } from './types'
import * as styles from './Header.module.scss'

@stores
class Header extends React.Component<Props> {
  previousScroll = 0
  headerElement = null
  lottieRef: any = null

  state = {
    isHamburgerStopped: true,
    isHamburgerPaused: true,
    hamburgerSpeed: 1,
  }

  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    if (!isNode) {
      document.addEventListener('scroll', this.handleScroll)
    }
  }

  playHamburger(speed: number) {
    // Start animation on first time
    if (this.state.isHamburgerStopped) {
      this.setState({
        isHamburgerStopped: false,
      })
    }
    if (speed === 1) {
      this.setState({
        isHamburgerPaused: false,
        hamburgerSpeed: 1,
      })
      setTimeout(() => {
        this.setState({
          isHamburgerPaused: true,
          hamburgerSpeed: -1,
        })
      }, 1000)
    } else {
      this.setState({
        isHamburgerPaused: false,
        hamburgerSpeed: -1,
      })
      setTimeout(() => {
        this.setState({
          isHamburgerPaused: true,
          hamburgerSpeed: 1,
        })
      }, 1000)
    }
  }

  componentDidUpdate() {
    if (!isNode) {
      this.handleScroll()
    }
  }

  componentWillUnmount() {
    if (!isNode) {
      document.removeEventListener('scroll', this.handleScroll)
    }
  }

  handleScroll = () => {
    if (!isNode) {
      const offset = 181
      const currentScroll = window.scrollY
      let direction = 0

      if (!this.headerElement) {
        this.headerElement = document.getElementById('header')
      } else {
        if (currentScroll > this.previousScroll) {
          //scrolled up
          direction = 2
        } else if (currentScroll < this.previousScroll) {
          //scrolled down
          direction = 1
        }
        if (window) {
          if (direction === 1) {
            this.headerElement.classList.add('up')
            this.headerElement.classList.remove('down')
          }
          if (direction === 2) {
            this.headerElement.classList.add('down')
            this.headerElement.classList.remove('up')
          }
          if (window.scrollY > offset) {
            if (!this.headerElement.classList.contains('active')) {
              this.headerElement.classList.add('active')
            }
          } else {
            if (this.headerElement.classList.contains('active')) {
              this.headerElement.classList.remove('active')
            }
          }
        }
        this.previousScroll = currentScroll
      }
    }
  }

  render() {
    const { isHamburgerStopped, isHamburgerPaused, hamburgerSpeed } = this.state

    const { ui, blok } = this.props
    const links = blok?.links ? [...blok.links] : []

    // Add home logotype to desktop links
    const desktopLinks = [...links]
    desktopLinks.splice(Math.floor(links.length / 2), 0, {
      slug: '/',
      _uid: 'home',
      title: 'Home',
    })

    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    return (
      <React.Fragment>
        <header
          id="header"
          className={
            ui?.activeMenu === 'nav'
              ? [styles.wrapper, styles.activeMobile].join(' ')
              : styles.wrapper
          }
        >
          <Box className={styles.container}>
            <Container height="100%">
              <Row
                className={[styles.content, 'hideMobile'].join(' ')}
                justifyContent="center"
              >
                <Column
                  height="100%"
                  justifyContent="flex-end"
                  width={[10 / 12]}
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-end"
                    className={styles.navigation}
                  >
                    {desktopLinks?.length > 0 &&
                      desktopLinks
                        .filter((link: any) => link.title)
                        .map((link: any, index: number) => {
                          const isLeft =
                            index > Math.floor(desktopLinks.length / 2)

                          return (
                            <Box
                              key={link._uid}
                              paddingX={5}
                              paddingBottom={5}
                              paddingTop={link._uid === 'home' ? 0 : 5}
                              flex={link._uid !== 'home' ? 1 : ''}
                            >
                              <LinkComponent
                                to={link.slug}
                                className={[
                                  styles.link,
                                  link._uid === 'home' ? styles.linkHome : '',
                                  ,
                                  isLeft ? styles.left : '',
                                ].join(' ')}
                              >
                                {link._uid === 'home' ? (
                                  <Flex
                                    className={styles.logotypeWrapper}
                                    justifyContent="center"
                                    width="167px"
                                  >
                                    <Logotype className={styles.logotype} />
                                  </Flex>
                                ) : (
                                  <Box>{link.title}</Box>
                                )}
                              </LinkComponent>
                            </Box>
                          )
                        })}
                  </Flex>
                </Column>
              </Row>

              <Row
                className="hideDesktop"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                height="100%"
              >
                <Column flex="1" alignItems="flex-start">
                  <Box
                    aria-label="Menu Button"
                    as="button"
                    onClick={() => {
                      if (ui?.activeMenu === 'nav') {
                        ui?.setActiveMenu('')
                        this.playHamburger(-1)
                      } else {
                        ui?.setActiveMenu('nav')
                        this.playHamburger(1)
                      }
                    }}
                    className={
                      ui?.activeMenu === 'nav'
                        ? [styles.hamburger, styles.active].join(' ')
                        : styles.hamburger
                    }
                  >
                    <div key="lottie" className={styles.lottie}>
                      <Lottie
                        isClickToPauseDisabled={true}
                        isStopped={isHamburgerStopped}
                        isPaused={isHamburgerPaused}
                        speed={hamburgerSpeed}
                        options={defaultOptions}
                        height={45}
                        width={60}
                      />
                    </div>
                  </Box>
                </Column>
                <Column
                  alignSelf="flex-start"
                  className={styles.mobileLogo}
                  width="auto"
                  onClick={() => {
                    if (ui?.activeMenu === 'nav') {
                      this.playHamburger(-1)
                    }
                    ui?.setActiveMenu('')
                  }}
                >
                  <LinkComponent to="/">
                    <Logotype width={106} height={65} />
                  </LinkComponent>
                </Column>
                <Column flex="1" />
              </Row>
            </Container>
          </Box>
        </header>
        <MobileNav
          onClick={(speed: number) => {
            this.playHamburger(speed)
          }}
          links={links}
        />
      </React.Fragment>
    )
  }
}

export default Header
