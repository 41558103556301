import React from 'react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import { ArrowRight, ArrowTopRight } from '../../Elements/Icon'
import * as styles from './BigCtaButton.module.scss'
import { Props } from './types'

const ButtonContent = ({ children, customIcon, useIcon }: Props) => (
  <Flex alignItems="center">
    <Box marginRight={4} textAlign="left">
      {children}
    </Box>
    {useIcon && (
      <React.Fragment>
        {customIcon ? (
          customIcon
        ) : (
          <ArrowRight width={26} height={30} color="#000000" strokeWidth={5} />
        )}
      </React.Fragment>
    )}
  </Flex>
)

const BigCtaButton = ({
  onClick,
  to,
  title,
  children,
  className,
  customIcon,
  theme,
  useIcon = true,
  type,
  disabled = false,
}: Props) => {
  const isExternal =
    to?.substr(0, 4) === 'http' ||
    to?.substr(0, 7) === 'mailto:' ||
    to?.substr(0, 9) === 'data:text'

  if (!onClick && to && isExternal && !customIcon) {
    customIcon = (
      <ArrowTopRight width={15} height={15} color="#000000" strokeWidth={5} />
    )
  }

  const themeName =
    theme && theme === 'blue'
      ? styles.blueTheme
      : theme === 'blueAlt'
      ? styles.blueAltTheme
      : theme && theme === 'whiteAlt'
      ? styles.whiteAltTheme
      : theme && theme === 'goldAlt'
      ? styles.goldAltTheme
      : ''

  const buttonStyles = classnames(
    styles.button,
    themeName,
    className,
    isExternal ? styles.isExternal : ''
  )
  return onClick || type ? (
    <Flex
      className={buttonStyles}
      as="button"
      type={type ? type : ''}
      onClick={onClick}
      disabled={disabled}
    >
      <ButtonContent customIcon={customIcon} useIcon={useIcon}>
        {children}
      </ButtonContent>
    </Flex>
  ) : to ? (
    <LinkComponent to={to} title={title} className={buttonStyles}>
      <ButtonContent customIcon={customIcon} useIcon={useIcon}>
        {children}
      </ButtonContent>
    </LinkComponent>
  ) : null
}

export default BigCtaButton
