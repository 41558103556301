import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { ArrowDown } from 'src/components/UI/Elements/Icon'
import Image from 'src/components/UI/Image/Image'

import * as styles from './PressArtist.module.scss'
import { Props } from './types'

const PressArtist = ({ blok }: Props) => {
  const { title, press_material, explanation_text, assets } = blok

  return (
    <SbEditable content={blok}>
      <Flex width={1} flexDirection={['column']}>
        <Box width={1} marginBottom={[3]} className={styles.title} as="h4">
          {title}
        </Box>
        <Flex
          marginBottom={[6, null, null, 15]}
          width={1}
          className={styles.explanation}
          alignItems="center"
        >
          {press_material && press_material?.filename && (
            <a
              href={press_material.filename}
              target="_blank"
              rel=" noopener noreferrer"
              download
            >
              <Box marginRight={1} as="span">
                {explanation_text}
              </Box>
            </a>
          )}
          <ArrowDown />
        </Flex>
        {assets &&
          assets.length > 0 &&
          assets.map((asset: any) => (
            <Flex
              key={asset._uid}
              className={styles.asset}
              width={1}
              marginBottom={[6, null, null, 10]}
              flexDirection="column"
            >
              {asset.file && asset.file.filename && (
                <a
                  href={asset.file.filename}
                  target="_blank"
                  rel=" noopener noreferrer"
                >
                  <Box className={styles.image} width={1}>
                    <Image
                      src={asset.file.filename}
                      alt={asset.explanation_text}
                      sizes={'40vw'}
                    />
                  </Box>
                  <Flex marginTop={2} flexDirection="column">
                    <Box as="span">{asset.explanation_text}</Box>
                  </Flex>
                </a>
              )}
            </Flex>
          ))}
      </Flex>
    </SbEditable>
  )
}

export default PressArtist
