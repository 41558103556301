import React from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './LightboxVideo.module.scss'
import { Props } from './types'
import YouTube from 'react-youtube'
import { CloseIcon } from '../UI/Elements/Icon'

const LightboxVideo = ({ id, onClose }: Props) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={styles.wrapper}
    >
      <YouTube
        onReady={(e: any) => e.target.playVideo()}
        // opts={opts}
        opts={{
          height: '100%',
          width: '100%',
        }}
        videoId={id}
      />
      <Box onClick={onClose} className={styles.closeButton} as="button">
        <CloseIcon width={42} height={42} color={'#FFFFFF'} />
      </Box>
    </Flex>
  )
}

export default LightboxVideo
