module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Polar Music Prize","short_name":"Polar Music Prize","start_url":"/","background_color":"#172835","theme_color":"#172835","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"800c14e1d4bb82011952f626ae5067da"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-18143616-1","head":false,"anonymize":true,"exclude":["/editor/**"],"respectDNT":false,"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"821732758171616"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
