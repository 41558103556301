import React, { useState } from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import YouTubeVideo from 'src/components/YouTubeVideo/YouTubeVideo'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './Media.module.scss'
import { Props } from './types'

const Media = ({ blok }: Props) => {
  const { type, image, youtube_id, text, source } = blok

  return (
    <SbEditable content={blok}>
      {type === 'image' && image?.filename && (
        <Image src={image.filename} sizes={'100vw'} alt={image.title} />
      )}
      {type === 'youtube' && youtube_id && (
        <YouTubeVideo id={youtube_id} image={image} />
      )}
      <Box marginTop={2} as="span" className={styles.text}>
        {text}
        {source?.length > 0 && (
          <span>
            {` (Source: `}
            {source[0]?.slug ? (
              <LinkComponent to={source[0].slug}>
                {source[0].title}
              </LinkComponent>
            ) : (
              source[0].title
            )}
            {`)`}
          </span>
        )}
      </Box>
    </SbEditable>
  )
}

export default Media
