import React, { useState, useEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { randomArray } from 'src/helpers/randomArray'
import LaureateList from 'src/components/LaureatesList/LaureatesList'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import BigCtaButton from 'src/components/UI/Buttons/BigCtaButton/BigCtaButton'

import * as styles from './LaureatesGrid.module.scss'
import { Props } from './types'

const sortLaureatesByYear = (laureates) => {
  if (laureates?.length === 0) {
    return []
  }

  const sortedLaureates = laureates.sort(function (a: any, b: any) {
    return b.year - a.year
  })

  return sortedLaureates
}

const getLaureatesByYear = (laureates, type) => {
  if (laureates?.length === 0) {
    return []
  }

  const laureatesByYear = sortLaureatesByYear(laureates).slice(
    0,
    type === 'condensed' ? 12 : 20
  )

  return laureatesByYear
}

const getRandomLaureates = (laureates, type) => {
  if (laureates?.length === 0) {
    return []
  }

  const shuffledLaureates = sortLaureatesByYear(
    randomArray(laureates).slice(0, type === 'condensed' ? 12 : 20)
  )

  return shuffledLaureates
}

const LaureatesGrid = ({ laureates, gridType = 'small', blok }: Props) => {
  const { title, use_buttons, type = 'full', sort_order = 'random' } = blok
  const laureatesByYear = sortLaureatesByYear(laureates)

  const [gridLaureates, setGridLaureates] = useState([])

  useEffect(() => {
    if (sort_order !== 'year') {
      setGridLaureates(getRandomLaureates(laureatesByYear, type))
    } else {
      setGridLaureates(getLaureatesByYear(laureatesByYear, type))
    }
  }, [laureatesByYear, sort_order])

  const handleClick = (laureates: any, event: any) => {
    event.preventDefault()
    setGridLaureates(getRandomLaureates(laureates, type))
  }

  return (
    <SbEditable content={blok}>
      {title && (
        <Container
          paddingTop={[20, null, null, 23]}
          paddingBottom={[10, null, null, 15]}
        >
          <Row alignItems="center" flexDirection="column">
            <Column width={[1, null, null, 10 / 12]}>
              <Box as="h2" className={styles.title}>
                {title}
              </Box>
            </Column>
          </Row>
        </Container>
      )}
      <Box className={styles.laureates}>
        <LaureateList laureates={gridLaureates} gridType={gridType} />
      </Box>

      {use_buttons && (
        <Flex
          flexDirection={['column', null, null, 'row']}
          justifyContent="flex-end"
          className={styles.ctaArea}
        >
          {sort_order === 'random' && (
            <BigCtaButton
              theme="blueAlt"
              onClick={(event: any) => handleClick(laureatesByYear, event)}
              useIcon={false}
            >
              Show more
            </BigCtaButton>
          )}
          <BigCtaButton to={'/laureates'}>All the Laureates</BigCtaButton>
        </Flex>
      )}
    </SbEditable>
  )
}

export default LaureatesGrid
