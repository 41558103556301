import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Column from 'src/components/UI/Grid/Column'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import PolarCircle from 'src/components/UI/Elements/PolarCircle/PolarCircle'
import { QuoteIcon } from 'src/components/UI/Elements/Icon'

import * as styles from './Quote.module.scss'
import { Props } from './types'

const Quote = ({ blok }: Props) => {
  const { quote, sub_title } = blok
  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper}>
        <Row>
          <Column
            className={styles.content}
            margin="auto"
            width={[1, null, null, 10 / 12]}
            flexDirection={['column']}
            paddingBottom={[18, null, null, '240px']}
            paddingTop={[18, null, null, '240px']}
          >
            <Box marginBottom={[4, null, null, 0]} className={styles.icon}>
              <QuoteIcon />
            </Box>
            <Box className={styles.quote} as="p">
              {quote}
            </Box>
            <Box marginTop={[7]} className={styles.sub} as="p">
              {sub_title}
            </Box>
          </Column>
        </Row>
        <PolarCircle size={1000} className={styles.circle} />
      </Container>
    </SbEditable>
  )
}

export default Quote
