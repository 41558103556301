import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'

import Image from 'src/components/UI/Image/Image'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import BigCtaButton from 'src/components/UI/Buttons/BigCtaButton/BigCtaButton'
import LightboxVideo from 'src/components/LightboxVideo/LightboxVideo'
import { PlayIcon } from 'src/components/UI/Elements/Icon'
import { getTitleParts } from 'src/helpers/getTitleParts'

import * as styles from './LaureateHero.module.scss'
import { Props } from './types'

const LaureateHero = ({
  year,
  title,
  image,
  imageMobile,
  youtube_id,
  hero_overlay,
}: Props) => {
  const [showVideo, setShowVideo] = useState(false)
  const namePartGroups = getTitleParts(title)

  return (
    <React.Fragment>
      <Flex className={styles.wrapper}>
        <Container
          className={styles.content}
          paddingTop={[0, null, null, '30vh']}
        >
          <Row>
            <Column
              width={[1, null, null, 3 / 12]}
              marginLeft={[0, null, null, `${(1 / 12) * 100}vw`]}
            >
              <Box marginBottom={[15]} as="span">
                {year} Laureate
              </Box>
              <Flex flexDirection="column" as="h1">
                {namePartGroups?.length > 0 &&
                  namePartGroups.map((part: any, index: number) => {
                    return part && Array.isArray(part) && part?.length > 0 ? (
                      <Box
                        className={styles.titlePart}
                        as="span"
                        width={1}
                        key={`${title}-part-${index}`}
                      >
                        {part.map(
                          (groupPart, index) =>
                            `${groupPart}${index < part.length - 1 ? ` ` : ``}`
                        )}
                      </Box>
                    ) : (
                      <Box
                        className={styles.titlePart}
                        as="span"
                        width={1}
                        key={`${title}-part-${index}-${part}`}
                      >
                        {part}
                      </Box>
                    )
                  })}
              </Flex>
            </Column>
          </Row>
        </Container>
        {image && image.filename && (
          <Image
            className={classnames(
              styles.background,
              imageMobile && imageMobile.filename ? 'hideMobile' : ''
            )}
            src={image.filename}
            alt={title}
            sizes="100vw"
          />
        )}
        {imageMobile && imageMobile.filename && (
          <Image
            className={classnames(styles.background, 'hideDesktop')}
            src={imageMobile.filename}
            alt={title}
            sizes="100vw"
          />
        )}
        {youtube_id && (
          <BigCtaButton
            onClick={() => setShowVideo(true)}
            className={styles.cta}
            customIcon={
              <PlayIcon
                width={26}
                height={30}
                color="#000000"
                strokeWidth={5}
              />
            }
          >
            {'Watch announcement video'}
          </BigCtaButton>
        )}
        {hero_overlay && <Box className={styles.overlay} />}
      </Flex>

      {showVideo && (
        <LightboxVideo onClose={() => setShowVideo(false)} id={youtube_id} />
      )}
    </React.Fragment>
  )
}

export default LaureateHero
