import React from 'react'
import { Flex } from 'reflexbox'

import LaureateCard from './LaureateCard/LaureateCard'
import * as styles from './LaureatesList.module.scss'
import { Props } from './types'

const LaureatesList = ({ laureates, gridType = 'default' }: Props) => {
  return (
    <Flex className={styles.wrapper} width={1} flexWrap="wrap">
      {laureates &&
        laureates.map((item: any) => (
          <LaureateCard item={item} type={gridType} key={item.slug} />
        ))}
    </Flex>
  )
}

export default LaureatesList
