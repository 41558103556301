import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import PolarCircle from 'src/components/UI/Elements/PolarCircle/PolarCircle'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './Banner.module.scss'
import { Props } from './types'

const Banner = ({ blok }: Props) => {
  const { title, description } = blok

  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper} paddingY={[20, null, null, 40]}>
        <PolarCircle position="bottom-right" className={styles.circle} />
        <Row flexDirection="column">
          <Column
            width={[1, null, 8 / 12]}
            marginLeft={[0, null, (1 / 12) * 100 + '%']}
          >
            {title && (
              <Box as="h2" className={styles.title}>
                {title}
              </Box>
            )}
            {description && (
              <Box
                marginTop={[15, null, null, 10]}
                as="p"
                className={styles.description}
              >
                {description}
              </Box>
            )}
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Banner
