import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { StoryblokLink } from 'src/globalTypes/types'
import { getLinkAttributes } from 'src/helpers/link'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './LinkList.module.scss'
import { Props } from './types'

const LinkList = ({ blok }: Props) => {
  const { title, links } = blok
  return (
    <SbEditable content={blok}>
      <Flex
        className={styles.wrapper}
        flexDirection={['column', null, null, 'row']}
        width={1}
      >
        <Flex
          className={styles.title}
          alignItems="center"
          justifyContent={['flex-start', null, null, 'flex-end']}
          width={[1, null, null, 2 / 8]}
          paddingX={[6, null, null, 16]}
        >
          {title && title}
        </Flex>

        <Flex
          width={[1, null, null, 6 / 8]}
          paddingX={[6, null, null, 8]}
          paddingY={[3, null, null, 0]}
          flexWrap={['wrap', null, null, 'nowrap']}
          flexDirection={['row', null, null, 'row']}
        >
          {links &&
            links.length > 0 &&
            links.map((link: StoryblokLink, index: number) => {
              const { title, url }: { title: string; url: string } = link
                ? getLinkAttributes(link)
                : { title: '', url: '' }
              return (
                <LinkComponent
                  key={'social-link' + index}
                  className={styles.link}
                  to={url}
                >
                  {title}
                </LinkComponent>
              )
            })}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default LinkList
