import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import YouTubeVideo from 'src/components/YouTubeVideo/YouTubeVideo'

import * as styles from './Video.module.scss'
import { Props } from './types'

const Video = (props: Props) => {
  const { className, blok } = props
  const {
    type,
    youtube_id,
    label,
    title,
    date,
    image,
    description,
    location,
    duration,
  } = blok
  const wrapperStyles = classnames(styles.wrapper, className)

  return (
    <SbEditable content={blok}>
      <Flex className={wrapperStyles} flexDirection="column">
        {type === 'youtube' && youtube_id && (
          <YouTubeVideo id={youtube_id} image={image} />
        )}
        <Flex
          flexDirection="column"
          paddingY={[5, null, null, 6]}
          paddingX={[0, null, null, 6]}
        >
          {label && (
            <Box as="span" className={styles.label}>
              {label}
            </Box>
          )}
          {title && (
            <Box
              as="span"
              marginTop={[2, null, null, 3]}
              className={styles.title}
            >
              {title}
            </Box>
          )}
          {/* {description && (
            <Box
              as="span"
              marginTop={[4, null, null, 4]}
              className={styles.text}
            >
              {description}
            </Box>
          )}
          {(location || date) && (
            <Box marginTop={[4, null, null, 4]} className={styles.caption}>
              {location && <Box as="span">{location} | </Box>}
              {date && <Box as="span">{date} | </Box>}
              {duration && <Box as="span">{duration}</Box>}
            </Box>
          )} */}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default Video
