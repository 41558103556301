// extracted by mini-css-extract-plugin
export var checkbox = "NominationForm-module--checkbox--M0Akz";
export var circle = "NominationForm-module--circle--izoyK";
export var ctaArea = "NominationForm-module--ctaArea--X5FT4";
export var description = "NominationForm-module--description--Ojtzx";
export var hidden = "NominationForm-module--hidden--rglkD";
export var nominateHeader = "NominationForm-module--nominateHeader--21QKy";
export var nominateImage = "NominationForm-module--nominateImage--uPKjd";
export var separator = "NominationForm-module--separator--xtSyX";
export var share = "NominationForm-module--share--150IG";
export var submit = "NominationForm-module--submit--EHM2v";
export var title = "NominationForm-module--title--1JSoh";
export var wrapper = "NominationForm-module--wrapper--c-ELs";