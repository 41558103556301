import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Image from 'src/components/UI/Image/Image'

import * as styles from './Member.module.scss'
import { Props } from './types'

const Member = ({ blok, onClick }: Props) => {
  const { image, name } = blok
  return (
    <SbEditable content={blok}>
      <Flex
        className={styles.wrapper}
        onClick={() => {
          if (onClick) {
            onClick(blok)
          }
        }}
        width={1}
        flexDirection="column"
      >
        <Box width={1} className={styles.imageWrapper}>
          {image && image.filename && (
            <Image src={image.filename} alt={name} sizes={'33vw'} />
          )}
        </Box>
        <Box
          className={styles.name}
          paddingTop={2}
          paddingBottom={3}
          paddingX={4}
          as="span"
        >
          {name}
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default Member
