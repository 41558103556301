import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './TitleAndText.module.scss'

const TitleAndText = ({ blok }: any) => {
  const { title, subtitle, text } = blok
  return (
    <SbEditable content={blok}>
      <Container
        className={styles.wrapper}
        paddingTop={[15, null, null, 20]}
        paddingBottom={[10, null, null, 15]}
      >
        <Row flexDirection="column" alignItems="center">
          <Column width={[1, null, null, 10 / 12]}>
            <Row flexDirection="column">
              <Column width={[1, null, null, 8 / 10]}>
                {title && (
                  <Box as="h2" className={styles.title}>
                    {title}
                  </Box>
                )}
                {subtitle && (
                  <Box as="h3" className={styles.subtitle}>
                    {subtitle}
                  </Box>
                )}
              </Column>
              <Column width={[1, null, null, 8 / 10]}>
                {text && (
                  <Box marginTop={[15, null, null, 10]}>
                    <Markdown source={text} />
                  </Box>
                )}
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default TitleAndText
