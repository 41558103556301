// extracted by mini-css-extract-plugin
export var active = "PageLaureatesLanding-module--active--Gs2NJ";
export var artist = "PageLaureatesLanding-module--artist--pCAE3";
export var artistInfo = "PageLaureatesLanding-module--artistInfo--FG0X6";
export var artistName = "PageLaureatesLanding-module--artistName--RExJi";
export var artistYear = "PageLaureatesLanding-module--artistYear--mik0v";
export var chapterHeader = "PageLaureatesLanding-module--chapterHeader--Gy7FC";
export var chevron = "PageLaureatesLanding-module--chevron--vIQM1";
export var customSelect = "PageLaureatesLanding-module--customSelect--SMQgG";
export var filter = "PageLaureatesLanding-module--filter--MScFc";
export var information = "PageLaureatesLanding-module--information--GUSKd";
export var left = "PageLaureatesLanding-module--left--+DiWD";
export var listImage = "PageLaureatesLanding-module--listImage--od7db";
export var nativeSelect = "PageLaureatesLanding-module--nativeSelect--GPq07";
export var right = "PageLaureatesLanding-module--right--7-Mur";
export var select = "PageLaureatesLanding-module--select--KEt94";
export var selectOptions = "PageLaureatesLanding-module--selectOptions--bM3HK";
export var separator = "PageLaureatesLanding-module--separator--Z8SUn";