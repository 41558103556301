import React from 'react'
import { Box, Flex } from 'reflexbox'
import { format } from 'date-fns'
import classnames from 'classnames'

import { truncateString } from 'src/helpers/truncateString'
import BigCtaButton from 'src/components/UI/Buttons/BigCtaButton/BigCtaButton'
import Container from 'src/components/UI/Grid/Container'
import Image from 'src/components/UI/Image/Image'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './Highlight.module.scss'
import { Props } from './types'

const Highlight = ({ item, imageLeft, theme, useSummary = false }: Props) => {
  const { date, label, title, content, slug, button } = item
  let { media, summary } = item

  let mediaType = media?.filename ? 'single' : media?.length > 0 ? 'grid' : null
  if (mediaType === 'grid' && media.length === 1) {
    media = { ...media[0].media }
    mediaType = 'single'
  }

  if (useSummary && !summary && content) {
    summary = truncateString(content, 260)
  }

  const themeName =
    theme && theme === 'blue'
      ? styles.blueTheme
      : theme && theme === 'gold'
      ? styles.goldTheme
      : styles.whiteTheme
  const wrapperStyles = classnames(styles.wrapper, themeName)
  const rowStyles = classnames(styles.row, imageLeft ? styles.imageLeft : null)

  return (
    <Container className={wrapperStyles}>
      <Row
        className={rowStyles}
        flexDirection={[
          'column-reverse',
          null,
          null,
          imageLeft ? 'row-reverse' : 'row',
        ]}
        alignItems={mediaType === 'grid' ? 'flex-start' : 'stretch'}
      >
        <Column
          // flex="2 1 50%"
          className={styles.content}
          width={[1, null, null, 6 / 12]}
          alignSelf="stretch"
        >
          <Row
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
          >
            <Column
              width={[1, null, null, 4 / 6]}
              paddingY={[9, null, null, 20]}
            >
              <Box paddingX={[0, null, null, '12px']}>
                <Box className={styles.label}>
                  {label && <Box as="span">{label}</Box>}
                  {date && (
                    <Box as="time">{format(new Date(date), 'MMMM do y')}</Box>
                  )}
                </Box>
                <Box
                  className={styles.title}
                  as="h2"
                  marginTop={[3, null, null, 5]}
                  marginBottom={[5]}
                >
                  {title}
                </Box>
                <Box className={styles.text}>
                  {summary ? (
                    <Box as="p">{summary}</Box>
                  ) : content ? (
                    <Markdown
                      themeLight={theme && theme === 'blue' ? true : false}
                      source={content}
                    />
                  ) : null}
                </Box>
              </Box>
            </Column>
            <Column width={[1]}>
              {button?.length > 0 ? (
                <BigCtaButton
                  className={styles.button}
                  theme={!theme ? 'whiteAlt' : theme + 'Alt'}
                  to={button[0].slug}
                >
                  {button[0].title}
                </BigCtaButton>
              ) : slug ? (
                <BigCtaButton
                  className={styles.button}
                  theme={!theme ? 'whiteAlt' : theme + 'Alt'}
                  to={slug}
                >
                  Read more
                </BigCtaButton>
              ) : null}
            </Column>
          </Row>
        </Column>

        <Column
          // flex="1 1 50%"
          className={[styles.content, styles.sticky].join(' ')}
          width={[1, null, null, 6 / 12]}
        >
          {mediaType === 'single' && media.filename && (
            <Box className={styles.image}>
              <Image
                src={media.filename}
                alt={media?.alt ? media.alt : title}
                sizes={{
                  mobile: '100vw',
                  tablet: '100vw',
                  desktop: '50vw',
                }}
              />
            </Box>
          )}
          {mediaType === 'grid' && media.length > 0 && (
            <Flex className={styles.imageGrid} flexWrap="wrap" height="100%">
              {media.map((item, index) => {
                const {
                  media: { filename, alt, id },
                } = item
                return (
                  <Box
                    className={styles.gridImage}
                    height={'50%'}
                    width={media.length === 3 && index > 0 ? 1 / 2 : 1}
                    key={id}
                  >
                    <Box>
                      <Image
                        src={filename}
                        alt={alt}
                        sizes={{
                          mobile: '100vw',
                          tablet: '100vw',
                          desktop: '50vw',
                        }}
                      />
                    </Box>
                  </Box>
                )
              })}
            </Flex>
          )}
        </Column>
      </Row>
    </Container>
  )
}

export default Highlight
