import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Components from 'src/storyblok/Components'
import SocialLinks from 'src/components/SocialLinks/SocialLinks'
import SEO from 'src/components/SEO/SEO'

// import * as styles from './PageStart.module.scss'
import { Props } from './types'

const PageHome = ({ blok }: Props) => {
  const { hero, content, laureates, articles } = blok
  const sortedArticles =
    articles && articles.length > 0
      ? articles.sort(function (a: any, b: any) {
          return new Date(b.date) - new Date(a.date)
        })
      : []

  // SEO from global data

  return (
    <SbEditable content={blok}>
      <Flex flexDirection="column" width={1}>
        {hero?.length > 0 &&
          hero.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        {content?.length > 0 &&
          content.map((blok: any) => {
            const { component } = blok

            return React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              laureates:
                component === 'laureates_grid' && laureates?.length > 0
                  ? laureates
                  : [],
              articles:
                component === 'articles_list' && sortedArticles?.length > 0
                  ? sortedArticles.slice(0, 3)
                  : [],
            })
          })}
      </Flex>
    </SbEditable>
  )
}

export default PageHome
