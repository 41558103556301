// extracted by mini-css-extract-plugin
export var activeMobile = "Header-module--activeMobile--SaZm+";
export var container = "Header-module--container--14icn";
export var content = "Header-module--content--WSPbw";
export var hamburger = "Header-module--hamburger--dEGnA";
export var left = "Header-module--left--UvkkZ";
export var link = "Header-module--link--+yMlA";
export var linkHome = "Header-module--linkHome--Hon3W";
export var logotype = "Header-module--logotype--0XM45";
export var logotypeWrapper = "Header-module--logotypeWrapper--CP8D3";
export var lottie = "Header-module--lottie--A1L1i";
export var mobileLogo = "Header-module--mobileLogo--EQ2qZ";
export var navigation = "Header-module--navigation--0KgIo";
export var upperNavigation = "Header-module--upperNavigation--AyA-P";
export var wrapper = "Header-module--wrapper--+Rccd";