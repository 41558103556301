import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Image from 'src/components/UI/Image/Image'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './FullWidthImage.module.scss'
import { Props } from './types'

const FullWidthImage = ({ blok, gridWidth = 1 }: Props) => {
  const { image, text, source } = blok

  return (
    <SbEditable content={blok}>
      <Flex flexDirection="column" width={'auto'} alignItems="center">
        <Box className={styles.image}>
          {image && image.filename && (
            <Image src={image.filename} sizes={'100vw'} alt={image.title} />
          )}
        </Box>
        <Container>
          <Row alignItems="center" justifyContent="center">
            <Column
              width={[1, null, null, gridWidth]}
              marginTop={2}
              as="span"
              className={styles.text}
            >
              {text}
              {source?.length > 0 && (
                <span>
                  {` (Source: `}
                  {source[0]?.slug ? (
                    <LinkComponent to={source[0].slug}>
                      {source[0].title}
                    </LinkComponent>
                  ) : (
                    source[0].title
                  )}
                  {`)`}
                </span>
              )}
            </Column>
          </Row>
        </Container>
      </Flex>
    </SbEditable>
  )
}

export default FullWidthImage
