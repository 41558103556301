import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import PolarCircle from 'src/components/UI/Elements/PolarCircle/PolarCircle'
import Column from 'src/components/UI/Grid/Column'
import Image from 'src/components/UI/Image/Image'
import Markdown from 'src/components/Markdown/Markdown'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'

import * as styles from './HalfTextHero.module.scss'
import { Props } from './types'

const HalfTextHero = ({ blok }: Props) => {
  const { title, text, markdown, image, use_filter } = blok
  const filter = use_filter ? styles.filter : ''
  return (
    <SbEditable content={blok}>
      <Box className={styles.wrapperOuter}>
        <Box className={styles.circleOuter}>
          <PolarCircle className={styles.circle} />
        </Box>
        <Container className={styles.wrapper} width={1}>
          <Row>
            <Column
              className={styles.contentWrapper}
              width={[1, null, null, 1 / 2]}
            >
              <Row
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                height="100%"
              >
                <Column
                  className={styles.content}
                  height="100%"
                  width={[1, null, null, 4 / 6]}
                >
                  <Flex
                    className={styles.contentInner}
                    height="100%"
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <Box as="h1" marginBottom={[25, null, null, 20]}>
                      {title}
                    </Box>
                    {markdown ? (
                      <Markdown source={markdown} />
                    ) : text ? (
                      <Box as="p">{text}</Box>
                    ) : null}
                  </Flex>
                </Column>
              </Row>
            </Column>
            <Column
              className={[styles.imageWrapper, styles.sticky].join(' ')}
              width={[1, null, null, 1 / 2]}
              alignSelf="flex-start"
            >
              <Box
                className={['hideMobile', styles.imageSection, filter].join(
                  ' '
                )}
              >
                {image && image.filename && (
                  <Image
                    src={image.filename}
                    alt={image.title}
                    sizes={'50vw'}
                  />
                )}
              </Box>
            </Column>
          </Row>
        </Container>
      </Box>
    </SbEditable>
  )
}

export default HalfTextHero
